import { useTable } from '@autone/ui';
import NoDataMessage from '@autone/ui/src/components/table/components/NoDataMessage';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { AutoneTable } from '../../components';
import { useAutoneCube, useBuildCubeQuery } from '../../cube';
import store from '../../redux/store';
import { tableSorter } from '../../utils';
import { calculateBestsellerMetrics } from '../../utils/bestsellers/calculateBestsellerMetrics';

const ProductModalTable = ({
  selected,
  config,
  dimension,
  selectedProductId,
  ...rest
}) => {
  const { type, title, name, id, maxHeight } = config || {};

  const [data, setData] = useState();
  const [query, setQuery] = useState(null);
  const { filters, activePage, selectedMetric } = store.getState().filters;

  const baseConfig = config?.config;

  useEffect(() => {
    // clear data when selected tab changes
    setData();
  }, [selected]);

  const getQueries = (data) => {
    const array = data.map((item) => {
      if (item?.query && (item?.active || item?.alwaysCalculate)) {
        return item?.query && item.query[0];
      }
      return null;
    });
    return array.filter((element) => element != null);
  };

  // Building an array of queries for cube based on a config, and adding filters
  const { cubeQuery, isLoading: getQuery } = useBuildCubeQuery({
    query,
    filters,
    activePage,
    productFilters: [
      {
        dimension: 'Product.product_id',
        title: selectedProductId,
        id: selectedProductId,
        active: true,
      },
    ],
  });

  // executing the queries -> returning resultSet
  const { resultSet, isLoading: queryLoading } = useAutoneCube({
    query: cubeQuery,
    type,
  });

  const { tableConfig, tableProps, tableData, handleSort } = useTable({
    data,
    customSorter: tableSorter,
    config: baseConfig || [],
  });

  useEffect(() => {
    const query = getQueries(baseConfig);
    setQuery(query);
  }, [filters, config]);

  useEffect(() => {
    if (resultSet) {
      const salesArray = resultSet[0]?.results;
      const inventoryArray = resultSet[1]?.results;

      const salesDict = Object.fromEntries(
        salesArray && salesArray.map((item) => [item[dimension], item]),
      );
      const inventoryDict = Object.fromEntries(
        inventoryArray && inventoryArray.map((item) => [item[dimension], item]),
      );

      const uniqueLocations = [
        ...new Set([...Object.keys(salesDict), ...Object.keys(inventoryDict)]),
      ];

      const combinedSalesInventoryData = uniqueLocations.map((location) => ({
        ...inventoryDict[location],
        ...salesDict[location],
      }));
      const dataWithMetrics = calculateBestsellerMetrics(
        combinedSalesInventoryData,
      );

      setData(dataWithMetrics);
    }
  }, [resultSet]);

  return (
    <AutoneTable
      cubeQuery={cubeQuery}
      // if the product isn't found (e.g. the product only exists in the inventory cube) we fall back to a no data state
      tableData={tableData}
      tableConfig={tableConfig || config?.config}
      tableProps={tableProps}
      title={title}
      maxHeight={maxHeight}
      name={name}
      isTabbed
      isLoading={queryLoading || getQuery}
      handleSort={handleSort}
      pagination={title === 'Product Performance'}
      selectedMetric={selectedMetric}
      id={id}
      noResultComponent={
        <NoDataMessage
          subtitle={`Sorry, we're unable to load data for this product.`}
        />
      }
      {...rest}
    />
  );
};

export default ProductModalTable;

ProductModalTable.propTypes = {
  config: PropTypes.object,
  dimension: PropTypes.string,
  selected: PropTypes.string,
  selectedProductId: PropTypes.string,
};
