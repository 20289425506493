import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCurrency: undefined,
};

export const priceOptionsSlice = createSlice({
  name: 'price-options',
  initialState,
  reducers: {
    LOAD_CURRENCIES: (state, action) => ({
      ...state,
      currencies: action.payload,
    }),
    LOAD_PRICE_TYPES: (state, action) => ({
      ...state,
      priceTypes: action.payload,
    }),
    SELECT_CURRENCY: (state, action) => ({
      ...state,
      selectedCurrency: action.payload,
    }),
    SELECT_PRICE_TYPE: (state, action) => ({
      ...state,
      selectedPriceType: action.payload,
    }),
  },
});

export const {
  LOAD_CURRENCIES,
  LOAD_PRICE_TYPES,
  SELECT_CURRENCY,
  SELECT_PRICE_TYPE,
} = priceOptionsSlice.actions;

export default priceOptionsSlice.reducer;
