import { useDebounceEffect } from '@autone/ui';
import { DEBOUNCE_DELAY } from '@autone/utils';
import { useState } from 'react';

import { useAppSelector as useSelector } from '../redux/store';
import { Filters } from '../types/filters';

const useGetDebouncedFilters = () => {
  const [debouncedFilters, setDebouncedFilters] = useState<Filters | null>(
    null,
  );
  const { filters, activePage } = useSelector((state) => state.filters);

  useDebounceEffect(
    () => {
      setDebouncedFilters(filters);
    },
    [filters, activePage],
    DEBOUNCE_DELAY,
  );

  return { filters: debouncedFilters, activePage };
};

export default useGetDebouncedFilters;
