import { isKeyInType } from '@autone/ui';

import { ADD_MULTIPLE_SELECTIONS } from '../../redux/filters';
import store, { AppDispatch, RootState } from '../../redux/store';
import { ActivePage } from '../../types';
import { Option } from '../../types/filters';
import { Mixpanel } from '../../utils/mixpanel';
import { FILTER } from '../../utils/mixpanel/eventTypes';

export const handleMultipleSelections = ({
  dispatch,
  dimension,
  operator,
  options,
  renderInSidebar,
  activePage,
}: {
  dispatch: AppDispatch;
  dimension: string;
  operator: string;
  options: Option[];
  renderInSidebar: boolean;
  activePage: ActivePage;
}) => {
  const { filters: filterState } = store.getState() as RootState;
  const { filters } = filterState;

  const filterGroupExists =
    filters &&
    activePage &&
    isKeyInType(filters, activePage) &&
    filters[activePage]?.some(
      (filterGroup) => filterGroup.dimension === dimension,
    );

  if (filterGroupExists) {
    dispatch(
      ADD_MULTIPLE_SELECTIONS({
        dimension,
        operator,
        options,
        renderInSidebar,
        activePage,
      }),
    );

    Mixpanel.track(FILTER, {
      dimension,
      options,
    });
  }
};
