export const noDimension = 'Not Defined';
export const VARIANCE_TYPE_MAPPING = {
  'this-year': 'this year',
  'last-year': 'last year',
  'last-last-year': 'last last year',
  'last-last-last-year': '3 years ago',
  'last-last-last-last-year': '4 years ago',
};

export const DATE_KEYS = ['Sales.date'];

export const PRODUCTS_PER_PAGE = 40;
export const APP_NAME = 'insights';
export const NA = 'N/A';
export const TODAY = 'today';
export const WEEK_TO_DATE = 'Week to date';
export const MONTH_TO_DATE = 'Month to date';
export const YEAR_TO_DATE = 'Year to date';
export const QUERY_LIMIT = 50000;
