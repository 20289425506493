import { isKeyInType } from '@autone/ui';
import { fNumberWithCommas, fReadableCurrency } from '@autone/utils';

import { Dimension } from '../../features';
import { BestsellersPerformanceData, Config } from '../../types';

import { ignoreFilters } from './ignoreFilters';

export const modalLocationConfig: Config<BestsellersPerformanceData> = {
  id: 'modal-location-table',
  type: 'table',
  name: 'location-modal',
  title: 'Location performance',
  maxHeight: '300px',
  layout: {
    md: 12,
    xs: 12,
  },
  config: [
    {
      id: 1,
      active: true,
      locked: true,
      type: 'custom',
      header: 'Location',
      dataKey: 'Location.description',
      customComponent: ({ dataRow, dataKey }) => (
        <Dimension dataRow={dataRow} dataKey={dataKey} />
      ),
      headerAlign: 'left',
      cellAlign: 'left',
      selectable: false,
    },
    {
      id: 3,
      active: true,
      locked: true,
      alwaysCalculate: true,
      type: 'custom',
      header: 'Total sales value',
      dataKey: 'Sales.totalSalesValue',
      customComponent: ({ dataRow, dataKey, selectedCurrency }) => {
        const value = isKeyInType(dataRow, dataKey)
          ? Number(dataRow[dataKey])
          : 0;

        return (
          <span>{dataRow && fReadableCurrency(value, selectedCurrency)}</span>
        );
      },
      headerAlign: 'right',
      cellAlign: 'right',
      selectable: false,
      query: [
        {
          dataKey: 'productSalesValue',
          query: {
            measure: ['Sales.totalSalesValue', 'Sales.totalSalesQuantity'],
            dimension: [
              'Location.description',
              //   'Sales.firstInLocationDate',
              'Product.product_id',
            ],
          },
        },
      ],
    },
    {
      id: 4,
      active: true,
      locked: true,
      alwaysCalculate: true,
      header: 'Total sales volume',
      dataKey: 'Sales.totalSalesQuantity',
      type: 'custom',
      customComponent: ({ dataRow, dataKey }) => {
        const value = isKeyInType(dataRow, dataKey)
          ? Number(dataRow[dataKey])
          : 0;

        return <span>{dataRow && fNumberWithCommas(value)}</span>;
      },
      headerAlign: 'right',
      cellAlign: 'right',
      selectable: false,
    },
    {
      id: 5,
      active: true,
      locked: true,
      alwaysCalculate: true,
      type: 'custom',
      header: 'Inventory value',
      dataKey: 'totalInventoryValue',
      customComponent: ({ dataRow, selectedCurrency }) => {
        return (
          <span>
            {dataRow &&
              fReadableCurrency(dataRow.totalInventoryValue, selectedCurrency)}
          </span>
        );
      },
      headerAlign: 'right',
      cellAlign: 'right',
      selectable: false,
    },
    {
      id: 6,
      active: true,
      locked: true,
      alwaysCalculate: true,
      type: 'custom',
      header: 'Inventory volume',
      customComponent: ({ dataRow }) => {
        return <span>{fNumberWithCommas(dataRow.totalInventoryQuantity)}</span>;
      },
      dataKey: 'totalInventoryQuantity',
      query: [
        {
          dataKey: 'productInventory',
          query: {
            measure: [
              'Inventory.currentOnHandQuantity',
              'Inventory.currentOnHandValue',
              'Inventory.currentInWarehouseQuantity',
              'Inventory.currentInWarehouseValue',
              'Inventory.currentInTransitQuantity',
              'Inventory.currentInTransitValue',
            ],
            dimension: ['Location.description', 'Product.product_id'],
            ignoreFilter: ignoreFilters,
          },
        },
      ],
      headerAlign: 'right',
      cellAlign: 'right',
      selectable: false,
    },
  ],
};
