export const SIDEBAR_FILTER_ORDER = [
  'brand_id',
  'department_id',
  'sub_department_id',
  'season_id',
  'event_id',
  'style_id',
  'style_desc',
  'color_id',
  'color_desc',
  'material_id',
  'product_id',
  'validity', // name is seasonality
  'merchandising_flag',
  'region_id',
  'location_id',
  'from_country_id',
  'to_country_id',
  'from_location_id',
  'to_location_id',
  // insights filter order below
  'Product.departmentDescription',
  'Product.subDepartmentDescription',
  'Product.seasonDescription',
  'Product.colorDescription',
  'Location.typeDescription',
  'Location.regionDescription',
  'Location.countryDescription',
  'Location.description',
  'Sales.markdown',
];

export const FILTER_OPTIONS_THRESHOLD = 5;
