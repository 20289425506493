import { format as dateFnsFormat } from 'date-fns';
import * as allLocales from 'date-fns/locale';
import dayjs from 'dayjs';
import moment from 'moment';

/**
 * Converts a given date to a readable string format.
 *
 * @param {Date | string} date - The date to be formatted.
 * @param {string} [format] - Optional. The format string to use. Defaults to 'MMM Do YY, h:mm a'.
 * @returns {string | undefined} The formatted date string, or undefined if the date is invalid.
 */
export function fISOToReadable(date: Date | string, format?: string) {
  if (!date) {
    return;
  }

  return moment(date).format(format || 'MMM Do YY, h:mm a');
}

export function fISOToReadableNoTime(date?: string | null) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('MMM Do, YYYY');
}

export function fISOToReadableNoTimeShort(date: string) {
  return moment(date).format('Do MMM YY');
}

export function fISOToReadableMonthYear(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('MMMM YYYY');
}

export function fISOToCalendarDate(
  date: string | Date | number,
): string | undefined {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('YYYY-MM-DD');
}

export function fISOToYearMonthDay(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('YYYY/MM/DD');
}

export function fISOToWeekNumber(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).format('W');
}

export function fISOToWeekStart(date: string) {
  if (!date) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment(date).startOf('week').format('MMM Do yy');
}

export function fISOToWeekNumberToDate(year: number, weekNumber: number) {
  if (!weekNumber) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return moment().year(year).day('Sunday').week(weekNumber).format();
}

export function getOrdinalDate(date: Date) {
  return moment(date).format('Do');
}

export function fISOToReadableDay(date: string) {
  return moment(date).format('ddd, Do MMMM');
}

export function fISOToTime(date: string) {
  return moment(date).format('HH:mm');
}

const dayMapping: { [key: string]: number } = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export function formatDay(day: string, format: string) {
  if (day in dayMapping) {
    const dayIndex = dayMapping[day];
    return moment().day(dayIndex).format(format);
  }

  return moment().day(day).format(format);
}

/**
 * Converts a  string to a dayjs date.
 *
 * @param {string} [yearMonth] - In the format '202412'
 * @returns {dayjs.Dayjs | null} The dayjs date object or null if the input is invalid.
 */
export const convertYearMonthToDate = (yearMonth?: string) => {
  if (!yearMonth) return null;
  const year = parseInt(yearMonth.slice(0, 4), 10);
  const month = parseInt(yearMonth.slice(4, 6), 10);
  return dayjs(`${year}-${month}-01`);
};

/**
 * Creates an array with the current and next month formatted as 'MMM YY'.
 *
 * @param {dayjs.Dayjs | null} date - The dayjs date object.
 * @returns {string[]} An array with the current and next month formatted as 'MMM YY'.
 */
export const createMonthYearArray = (date: dayjs.Dayjs | null) => {
  if (!date) return [];
  const currentMonthYear = date.format('MMM YY');
  const nextMonthYear = date.add(1, 'month').format('MMM YY');
  return [currentMonthYear, nextMonthYear];
};

const localeMapping = {
  en: allLocales.enGB,
  fr: allLocales.fr,
  it: allLocales.it,
};

const getLocale = (locale: string) => {
  if (locale.startsWith('en') || !Object.keys(localeMapping).includes(locale)) {
    return localeMapping.en;
  }
  return localeMapping[locale as keyof typeof localeMapping];
};

export const formatDate = (date: Date, format: string, lang: string = 'en') =>
  dateFnsFormat(date, format, {
    locale: getLocale(lang),
  });
