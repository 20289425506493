import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  selectedProduct: Record<string, unknown> | undefined;
} = {
  selectedProduct: undefined,
};

export const selectedProductSlice = createSlice({
  name: 'selected-product',
  initialState,
  reducers: {
    LOAD_SELECTED_PRODUCT: (
      state,
      action: { payload: Record<string, unknown> | undefined },
    ) => ({
      ...state,
      selectedProduct: action?.payload,
    }),
  },
});

export const { LOAD_SELECTED_PRODUCT } = selectedProductSlice.actions;

export default selectedProductSlice.reducer;
