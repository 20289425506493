import { isKeyInType } from '@autone/ui';

//@ts-ignore TODO: type this with the config folder
import { compare, comparePercent, current } from '../config';
import {
  ExplorerPerformanceData,
  RetailPerformanceData,
  RetailPerformanceTotals,
} from '../types';

const sumTotals = (
  tableData: (RetailPerformanceData | ExplorerPerformanceData)[],
  period: string,
) =>
  tableData.reduce((acc, obj) => {
    const number = isKeyInType(obj, period) ? Number(obj?.[period]) : 0;
    return acc + number;
  }, 0);

export const getTableTotals = (
  tableData: (RetailPerformanceData | ExplorerPerformanceData)[],
): RetailPerformanceTotals => {
  // Sum table data
  const today = sumTotals(tableData, 'today');
  const todayCompare = sumTotals(tableData, 'today-compare');
  const wtd = sumTotals(tableData, 'wtd');
  const wtdCompare = sumTotals(tableData, 'wtd-compare');
  const mtd = sumTotals(tableData, 'mtd');
  const mtdCompare = sumTotals(tableData, 'mtd-compare');
  const ytd = sumTotals(tableData, 'ytd');
  const ytdCompare = sumTotals(tableData, 'ytd-compare');
  const primarySalesVal = sumTotals(tableData, 'primary_sales_val');
  const primarySalesVol = sumTotals(tableData, 'primary_sales_vol');
  const comparisonSalesVal = sumTotals(tableData, 'comparison_sales_val');
  const comparisonSalesVol = sumTotals(tableData, 'comparison_sales_vol');

  // store in object & calculate current and compare
  return {
    today: current({ today }, 'today'),
    'today-previous': todayCompare,
    'today-compare': compare({ today, todayCompare }, 'todayCompare', 'today'),
    'today-compare-percent': comparePercent(
      { today, 'today-compare': wtdCompare },
      'today',
    ),
    wtd: current({ wtd }, 'wtd'),
    'wtd-previous': wtdCompare,
    'wtd-compare': compare({ wtd, wtdCompare }, 'wtdCompare', 'wtd'),
    'wtd-compare-percent': comparePercent(
      { wtd, 'wtd-compare': wtdCompare },
      'wtd',
    ),
    mtd: current({ mtd }, 'mtd'),
    'mtd-previous': mtdCompare,
    'mtd-compare': compare({ mtd, mtdCompare }, 'mtdCompare', 'mtd'),
    'mtd-compare-percent': comparePercent(
      { mtd, 'mtd-compare': mtdCompare },
      'mtd',
    ),
    ytd: current({ ytd }, 'ytd'),
    'ytd-previous': ytdCompare,
    'ytd-compare': compare({ ytd, ytdCompare }, 'ytdCompare', 'ytd'),
    'ytd-compare-percent': comparePercent(
      { ytd, 'ytd-compare': ytdCompare },
      'ytd',
    ),
    primary_sales_val: current(
      { primary_sales_val: primarySalesVal },
      'primary_sales_val',
    ),
    primary_sales_vol: current(
      { primary_sales_vol: primarySalesVol },
      'primary_sales_vol',
    ),
    comparison_sales_val: current(
      { comparison_sales_val: comparisonSalesVal },
      'comparison_sales_val',
    ),
    comparison_sales_vol: current(
      { comparison_sales_vol: comparisonSalesVol },
      'comparison_sales_vol',
    ),
    difference_sales_val: compare(
      {
        primary_sales_val: primarySalesVal,
        comparison_sales_val: comparisonSalesVal,
      },
      'comparisonSalesVal',
      'primarySalesVal',
    ),
    difference_sales_vol: compare(
      {
        primary_sales_vol: primarySalesVol,
        comparison_sales_vol: comparisonSalesVol,
      },
      'comparisonSalesVol',
      'primarySalesVol',
    ),
    percentage_difference_sales_val: comparePercent(
      {
        primary_sales_val: primarySalesVal,
        comparison_sales_val: comparisonSalesVal,
      },
      'primary_sales_val',
    ),
    percentage_difference_sales_vol: comparePercent(
      {
        primary_sales_vol: primarySalesVol,
        comparison_sales_vol: comparisonSalesVol,
      },
      'primary_sales_vol',
    ),
  };
};
