/* eslint-disable complexity */
import { fNumber, fReadableCurrency } from '@autone/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { current } from '../../config/retail/calcs';

const Current = ({ dataRow, dataKey, metric }) => {
  const { selectedCurrency } = useSelector((state) => state.priceOptions);

  if (metric === 'value') {
    return fReadableCurrency(
      current(dataRow, dataKey, metric),
      selectedCurrency,
    );
  }
  if (metric === 'volume') {
    return fNumber(current(dataRow, dataKey, metric));
  }
  return <>No matching metric</>;
};

export default Current;

Current.propTypes = {
  dataRow: PropTypes.object,
  dataKey: PropTypes.string,
  metric: PropTypes.string,
};
