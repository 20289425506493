import { isKeyInType } from '@autone/ui';
import { capitalizeFirstLetter } from '@autone/utils';
import React from 'react';

import { noDimension } from '../../config/consts';
import {
  BestsellersPerformanceData,
  ExplorerPerformanceData,
  RetailPerformanceData,
} from '../../types';

type Props = {
  dataRow?:
    | RetailPerformanceData
    | ExplorerPerformanceData
    | BestsellersPerformanceData;
  dataKey?: string;
};

const Dimension = ({ dataRow, dataKey }: Props) => {
  return (
    <span>
      {dataRow &&
        isKeyInType(dataRow, dataKey) &&
        (dataRow[dataKey]
          ? capitalizeFirstLetter(dataRow[dataKey])
          : noDimension)}
    </span>
  );
};

export default Dimension;
