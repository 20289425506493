/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { useOutsideClick } from '@autone/ui';
import { fISOToYearMonthDay } from '@autone/utils';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Card } from '@mui/material';
import { addDays } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useDispatch } from 'react-redux';

import { handleClearDate } from '../../functions/actions';
import { ADD_DATE_SELECTIONS } from '../../redux/filters';

const Calendar = ({ isLoading }) => {
  const dispatch = useDispatch();

  const myRef = useRef();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), -1),
    key: 'selection',
  });

  const [formattedStart, setFormattedStart] = useState(null);
  const [formattedEnd, setFormattedEnd] = useState(null);

  const previousValues = useRef({ formattedStart, formattedEnd });

  const handleSelect = (date) => {
    // clear previous saved value when we re-select
    previousValues.current = { formattedStart: null, formattedEnd: null };

    setSelectedRange(date.selection);
    const { startDate, endDate } = date.selection;
    setFormattedStart(fISOToYearMonthDay(startDate));
    setFormattedEnd(fISOToYearMonthDay(endDate));

    handleClearDate({
      dispatch,
      dimension: 'Sales.date',
      activePage: 'retail',
    });
  };

  const dateSelectionObject = {
    dispatch,
    dimension: 'Sales.date',
    title: 'Sales.date',
    operator: 'inDateRange',
    renderInSidebar: false,
    activePage: 'retail',
  };

  //  previousValues.current = { name, age };
  const selectDates = useCallback(() => {
    if (
      previousValues.current.formattedStart !== formattedStart ||
      previousValues.current.formattedEnd !== formattedEnd
    ) {
      // if the start and end dates are the same, we need to append hourly values
      if (formattedStart === formattedEnd) {
        dispatch(
          ADD_DATE_SELECTIONS({
            ...dateSelectionObject,
            options: [
              {
                dimension: `Sales.date`,
                title: formattedStart.toString(),
                id: `${formattedStart.toString()} 00:00:00`,
                active: true,
              },
              {
                dimension: `Sales.date`,
                title: formattedEnd.toString(),
                id: `${formattedStart.toString()} 23:59:59`,
                active: true,
              },
            ],
          }),
        );
      } else {
        dispatch(
          ADD_DATE_SELECTIONS({
            ...dateSelectionObject,
            options: [
              {
                dimension: `Sales.date`,
                title: formattedStart.toString(),
                id: formattedStart.toString(),
                active: true,
              },
              {
                dimension: `Sales.date`,
                title: formattedEnd.toString(),
                id: formattedEnd.toString(),
                active: true,
              },
            ],
          }),
        );
      }
      // update previous values to stop re-triggered upon closing with no date change
      previousValues.current = { formattedStart, formattedEnd };
    }
  }, [formattedStart, formattedEnd]);

  const maxDate = addDays(new Date(), -1);

  useOutsideClick(myRef, () => {
    setShowCalendar(false);
    if (showCalendar) {
      selectDates();
    }
  });

  return (
    <Box ref={myRef} sx={{ marginLeft: 'auto', mr: 1 }}>
      <LoadingButton
        loading={isLoading}
        color="primary"
        variant="contained"
        onClick={() => {
          setShowCalendar(!showCalendar);
        }}
        startIcon={<CalendarMonthOutlinedIcon />}
        sx={{ textTransform: 'none' }}
        size="large"
      >
        Select date range
      </LoadingButton>
      {showCalendar ? (
        <Card
          sx={{
            position: 'absolute',
            mt: 5,
            right: 40,
            zIndex: 99,
          }}
        >
          <DateRangePicker
            ranges={[selectedRange]}
            onChange={handleSelect}
            maxDate={maxDate}
          />
        </Card>
      ) : null}
    </Box>
  );
};

export default Calendar;

Calendar.propTypes = {
  isLoading: PropTypes.bool,
};
