import { isKeyInType } from '@autone/ui';
import { useEffect, useState } from 'react';

import { NA } from '../config/consts';
import {
  compare,
  comparePercent,
  comparePercentExplorer,
  DataType,
  formatDateKeys,
  mixCompare,
  mixCurrent,
} from '../config/retail/calcs';
import { RetailPerformanceTotals } from '../types';

type Keys = {
  key: string;
};

type KeysWithType = {
  key: string;
  type: string;
};

type HeaderList = {
  key: string;
  label: string;
};

type CsvData = {
  difference_sales_val: number | string;
  difference_sales_vol: number | string;
  percentage_difference_sales_val: string;
  percentage_difference_sales_vol: string;
}[];

const useDataExport = ({
  dataToExport,
  headerList,
  percentageKeys,
  compareKeys,
  mixCurrentKeys,
  mixCompareKeys,
  calcType,
  totals,
}: {
  dataToExport: DataType[] | undefined;
  headerList: { key: string; label: string }[];
  percentageKeys?: KeysWithType[];
  compareKeys?: KeysWithType[];
  mixCurrentKeys?: Keys[] | null;
  mixCompareKeys?: KeysWithType[] | null;
  calcType?: string | null;
  totals?: RetailPerformanceTotals;
}) => {
  const [csvData, setCsvData] = useState<CsvData>([]);
  const reduceArray = (array: Record<string, any>[] | undefined) =>
    array?.reduce<Record<string, any>>((result, item) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {}) ?? {};

  const calculatePercentageMetric = (
    keys: KeysWithType[],
    item: DataType | undefined,
  ) =>
    keys.map(({ key: keyName, type }) => ({
      [keyName]: item ? comparePercent(item, type) : NA,
    }));

  const calculateMetric = (keys: KeysWithType[], item: DataType | undefined) =>
    keys.map(({ key: keyName, type }) => ({
      [keyName]: item ? compare(item, keyName, type) : NA,
    }));

  const calculateMix = (
    keys: Keys[] | null | undefined,
    item: DataType | undefined,
    totals: DataType | undefined,
  ) =>
    keys?.map(({ key: keyName }) => ({
      [keyName]: item && totals ? mixCurrent(item, keyName, totals) : NA,
    })) ?? [];

  const calculateMixComp = (
    keys: KeysWithType[] | null | undefined,
    item: DataType | undefined,
    totals: DataType | undefined,
  ) =>
    keys?.map(({ key: keyName, type }) => ({
      [keyName]: item && totals ? mixCompare(item, keyName, type, totals) : NA,
    })) ?? [];

  useEffect(() => {
    if (dataToExport) {
      const csvData = dataToExport.map((item) => {
        const percentages = calculatePercentageMetric(
          percentageKeys ?? [],
          item,
        );

        const percentageObj = reduceArray(percentages);

        const compares = calculateMetric(compareKeys ?? [], item);
        const compareObj = reduceArray(compares);

        const mix = calculateMix(mixCurrentKeys, item, totals);
        const mixObj = reduceArray(mix);

        const mixComp = calculateMixComp(mixCompareKeys, item, totals);
        const mixCompObj = reduceArray(mixComp);

        const difference_sales_val = item
          ? compare(item, 'comparison_sales_val', 'primary_sales_val')
          : NA;

        const difference_sales_vol = item
          ? compare(item, 'comparison_sales_vol', 'primary_sales_vol')
          : NA;

        const percentage_difference_sales_val = item
          ? comparePercentExplorer(
              item,
              'primary_sales_val',
              'comparison_sales_val',
            )
          : NA;

        const percentage_difference_sales_vol = item
          ? comparePercentExplorer(
              item,
              'primary_sales_vol',
              'comparison_sales_vol',
            )
          : NA;

        const formattedItemWithDates = item ? formatDateKeys(item) : {};

        return {
          ...formattedItemWithDates,
          ...percentageObj,
          ...compareObj,
          ...mixObj,
          ...mixCompObj,
          difference_sales_val,
          difference_sales_vol,
          percentage_difference_sales_val,
          percentage_difference_sales_vol,
        };
      });

      setCsvData(csvData);
    }
  }, [
    dataToExport,
    calcType,
    percentageKeys,
    compareKeys,
    mixCurrentKeys,
    mixCompareKeys,
    totals,
  ]);

  const createFormattedCsVData = (
    csvData: CsvData,
    csvHeader: HeaderList[],
  ) => {
    if (!csvData || !csvHeader) {
      return [];
    }

    const formattedData = csvData.map((item) =>
      csvHeader.map(({ key }) => (isKeyInType(item, key) ? item?.[key] : '')),
    );

    return [['SEP=,'], [csvHeader.map(({ label }) => label)], ...formattedData];
  };

  return {
    csvData,
    csvHeader: [...headerList],
    createFormattedCsVData,
  };
};

export default useDataExport;
