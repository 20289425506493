export const NoDataSvg = ({
  height = 120,
  width = 120,
}: {
  height?: number | string;
  width?: number | string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.48">
      <path
        opacity="0.48"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1902 86.0659L22.6459 87.5217L24.1062 86.0619L24.7302 86.6858L23.2696 88.1453L24.7262 89.601L24.1005 90.2266L22.6438 88.771L21.1845 90.2306L20.5605 89.6067L22.0201 88.1474L20.5645 86.6915L21.1902 86.0659Z"
        fill="#919EAB"
      />
      <path
        opacity="0.48"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.2381 12.7675L88.6937 14.2233L90.1541 12.7635L90.7781 13.3873L89.3175 14.8469L90.7741 16.3025L90.1483 16.9281L88.6916 15.4725L87.2323 16.9321L86.6083 16.3083L88.0679 14.849L86.6124 13.393L87.2381 12.7675Z"
        fill="#919EAB"
      />
      <path
        opacity="0.72"
        d="M61.4784 104.835C61.7522 104.562 62.1233 104.409 62.5101 104.409C62.897 104.409 63.268 104.562 63.5419 104.835L64.5809 105.874C64.717 106.009 64.8258 106.171 64.8995 106.348C64.9734 106.526 65.0115 106.716 65.0115 106.909C65.0115 107.101 64.9734 107.291 64.8995 107.469C64.8258 107.646 64.7178 107.807 64.5817 107.942L63.5419 108.982C63.268 109.255 62.897 109.409 62.5101 109.409C62.1233 109.409 61.7522 109.255 61.4784 108.982L60.4393 107.943C60.3032 107.808 60.1945 107.646 60.1207 107.469C60.0468 107.291 60.0088 107.101 60.0088 106.909C60.0088 106.716 60.0468 106.526 60.1207 106.348C60.1944 106.171 60.3024 106.01 60.4385 105.875L61.4784 104.835Z"
        fill="#919EAB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.6978 39.0406C96.6977 39.0406 96.6979 39.0405 96.6978 39.0406L95.6585 40.0796L95.2196 39.6406L95.6571 40.081C95.6368 40.1011 95.6207 40.1251 95.6097 40.1516C95.5987 40.178 95.593 40.2063 95.593 40.235C95.593 40.2636 95.5987 40.292 95.6097 40.3184C95.6207 40.3448 95.6368 40.3688 95.6571 40.389L95.6585 40.3904L96.6978 41.4294C96.6977 41.4293 96.6979 41.4295 96.6978 41.4294C96.739 41.4704 96.7948 41.4935 96.8529 41.4935C96.911 41.4935 96.9667 41.4705 97.0078 41.4296C97.0077 41.4297 97.0079 41.4296 97.0078 41.4296L98.0487 40.389C98.069 40.3688 98.0851 40.3448 98.0961 40.3184C98.1072 40.2919 98.1128 40.2635 98.1128 40.235C98.1128 40.2064 98.1072 40.1781 98.0961 40.1516C98.0851 40.1251 98.069 40.1011 98.0487 40.081L98.0473 40.0796L97.008 39.0406C97.008 39.0405 97.0081 39.0406 97.008 39.0406C96.9669 38.9997 96.911 38.9764 96.8529 38.9764C96.7948 38.9764 96.739 38.9996 96.6978 39.0406ZM94.7813 39.2011L95.8211 38.1616C96.095 37.8884 96.466 37.735 96.8529 37.735C97.2397 37.735 97.6108 37.8884 97.8847 38.1616L97.8852 38.1621L98.9237 39.2003C98.924 39.2005 98.9242 39.2008 98.9244 39.201C99.0606 39.3365 99.1686 39.4974 99.2423 39.6747C99.3162 39.8521 99.3543 40.0426 99.3543 40.235C99.3543 40.4274 99.3162 40.6178 99.2423 40.7953C99.1686 40.9725 99.0606 41.1335 98.9245 41.2689C98.9242 41.2692 98.924 41.2694 98.9237 41.2697L97.8847 42.3084C97.6108 42.5816 97.2397 42.735 96.8529 42.735C96.466 42.735 96.095 42.5816 95.8211 42.3084L94.7821 41.2697C94.7818 41.2694 94.7816 41.2691 94.7813 41.2689C94.6452 41.1334 94.5372 40.9725 94.4635 40.7953C94.3896 40.6177 94.3516 40.4273 94.3516 40.235C94.3516 40.0427 94.3896 39.8522 94.4635 39.6747C94.5372 39.4974 94.6452 39.3365 94.7813 39.2011Z"
        fill="#919EAB"
      />
      <path
        opacity="0.48"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.552 66.3173C104.701 66.2788 104.858 66.2779 105.008 66.3152C105.173 66.356 105.322 66.4408 105.442 66.5601C105.561 66.6796 105.646 66.8294 105.687 66.9934C105.724 67.1436 105.723 67.3004 105.685 67.4496L105.109 70.541C105.062 70.7838 104.909 71.1333 104.522 71.2523C104.135 71.3713 103.812 71.1677 103.638 70.9932L101.009 68.3652C100.831 68.1887 100.628 67.865 100.748 67.4773C100.868 67.0898 101.219 66.9374 101.464 66.8921C101.465 66.8921 101.465 66.892 101.465 66.892L104.552 66.3173ZM104.448 67.5539L102.291 67.9553L104.046 69.7099L104.448 67.5539Z"
        fill="#919EAB"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.46 94.2659C102.609 94.2274 102.766 94.2265 102.916 94.2638C103.08 94.3046 103.23 94.3894 103.349 94.5087C103.469 94.6282 103.554 94.778 103.594 94.942C103.632 95.0922 103.631 95.249 103.592 95.3982L103.016 98.4896C102.97 98.7324 102.817 99.0819 102.43 99.2009C102.042 99.3199 101.72 99.1163 101.545 98.9418L98.9167 96.3138C98.7386 96.1373 98.5356 95.8136 98.6558 95.4259C98.776 95.0384 99.1263 94.886 99.3721 94.8407C99.3724 94.8407 99.3727 94.8406 99.373 94.8406L102.46 94.2659ZM102.356 95.5025L100.199 95.9039L101.954 97.6585L102.356 95.5025Z"
        fill="#637381"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5004 72.4729C12.3186 72.4729 12.1408 72.5268 11.9896 72.6278C11.8383 72.7289 11.7205 72.8724 11.651 73.0402C11.5814 73.2081 11.5632 73.3929 11.5987 73.5711C11.6341 73.7494 11.7217 73.9131 11.8502 74.0416C11.9787 74.1701 12.1425 74.2576 12.321 74.2931C12.4996 74.3286 12.6845 74.3104 12.8523 74.2409C13.0203 74.1714 13.1639 74.0535 13.265 73.9023C13.366 73.7512 13.4199 73.5736 13.4199 73.3918C13.4199 73.1482 13.323 72.9144 13.1506 72.7421C12.9783 72.5697 12.7444 72.4729 12.5004 72.4729ZM11.1113 71.3132C11.5225 71.0385 12.0059 70.8918 12.5004 70.8918C13.1635 70.8918 13.7995 71.1551 14.2684 71.624C14.7374 72.0928 15.0009 72.7288 15.0009 73.3918C15.0009 73.8864 14.8542 74.3697 14.5794 74.7808C14.3047 75.1919 13.9142 75.5124 13.4572 75.7017C13.0002 75.8909 12.4975 75.9402 12.0127 75.8438C11.5278 75.7474 11.0822 75.5094 10.7324 75.1597C10.3827 74.8101 10.1446 74.3647 10.0481 73.8797C9.95156 73.3947 10.0011 72.8919 10.1904 72.435C10.3797 71.9781 10.7002 71.5878 11.1113 71.3132Z"
        fill="#637381"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5424 18.4017C54.3606 18.4017 54.1828 18.4556 54.0316 18.5567C53.8803 18.6577 53.7625 18.8012 53.693 18.969C53.6234 19.1369 53.6052 19.3217 53.6407 19.5C53.6761 19.6782 53.7637 19.8419 53.8922 19.9705C54.0207 20.0989 54.1845 20.1865 54.363 20.222C54.5416 20.2575 54.7265 20.2393 54.8943 20.1698C55.0623 20.1002 55.2059 19.9824 55.307 19.8312C55.408 19.68 55.4619 19.5024 55.4619 19.3207C55.4619 19.077 55.365 18.8432 55.1926 18.6709C55.0203 18.4986 54.7864 18.4017 54.5424 18.4017ZM53.1533 17.242C53.5645 16.9674 54.0479 16.8207 54.5424 16.8207C55.2055 16.8207 55.8415 17.084 56.3104 17.5528C56.7794 18.0216 57.0429 18.6576 57.0429 19.3207C57.0429 19.8152 56.8962 20.2986 56.6214 20.7097C56.3467 21.1207 55.9562 21.4413 55.4992 21.6305C55.0422 21.8198 54.5395 21.869 54.0547 21.7726C53.5698 21.6762 53.1242 21.4383 52.7744 21.0886C52.4247 20.7389 52.1865 20.2935 52.09 19.8085C51.9936 19.3235 52.0431 18.8207 52.2324 18.3639C52.4217 17.9069 52.7422 17.5166 53.1533 17.242Z"
        fill="#637381"
      />
    </g>
    <g opacity="0.48">
      <g filter="url(#filter0_di_2575_2852044)">
        <path
          d="M27.9998 49.238H91.9996V78.1903C91.9996 82.3982 88.5884 85.8094 84.3806 85.8094H35.6188C31.4109 85.8094 27.9998 82.3982 27.9998 78.1903V49.238Z"
          fill="#DFE3E8"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9044 39.3334C36.6242 39.3334 35.6187 40.3389 35.6187 41.6191C35.6187 42.8989 36.6242 43.9048 37.9044 43.9048C39.1845 43.9048 40.1901 42.8989 40.1901 41.6191C40.1901 40.3389 39.1845 39.3334 37.9044 39.3334Z"
        fill="#C4CDD5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1424 39.3334C43.8623 39.3334 42.8567 40.3389 42.8567 41.6191C42.8567 42.8989 43.8623 43.9048 45.1424 43.9048C46.4226 43.9048 47.4281 42.8989 47.4281 41.6191C47.4281 40.3389 46.4226 39.3334 45.1424 39.3334Z"
        fill="#C4CDD5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3805 39.3334C51.1004 39.3334 50.0948 40.3389 50.0948 41.6191C50.0948 42.8989 51.1004 43.9048 52.3805 43.9048C53.6607 43.9048 54.6662 42.8989 54.6662 41.6191C54.6662 40.3389 53.6607 39.3334 52.3805 39.3334Z"
        fill="#C4CDD5"
      />
      <g filter="url(#filter1_di_2575_2852044)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.9996 40.0954V49.2381H27.9998L27.9998 40.0959C27.9998 36.7294 30.7289 34 34.0955 34H85.9044C89.2707 34 91.9996 36.7291 91.9996 40.0954ZM37.9045 39.3333C36.6243 39.3333 35.6188 40.3389 35.6188 41.619C35.6188 42.8989 36.6243 43.9047 37.9045 43.9047C39.1846 43.9047 40.1902 42.8989 40.1902 41.619C40.1902 40.3389 39.1846 39.3333 37.9045 39.3333ZM45.1426 39.3333C43.8624 39.3333 42.8569 40.3389 42.8569 41.619C42.8569 42.8989 43.8624 43.9047 45.1426 43.9047C46.4227 43.9047 47.4283 42.8989 47.4283 41.619C47.4283 40.3389 46.4227 39.3333 45.1426 39.3333ZM50.0949 41.619C50.0949 40.3389 51.1005 39.3333 52.3806 39.3333C53.6608 39.3333 54.6664 40.3389 54.6664 41.619C54.6664 42.8989 53.6608 43.9047 52.3806 43.9047C51.1005 43.9047 50.0949 42.8989 50.0949 41.619Z"
          fill="#919EAB"
        />
      </g>
      <path
        opacity="0.48"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.3255 54.0601H63.7209C62.6926 54.0601 61.8605 54.8908 61.8605 55.9175V78.2063C61.8605 79.2329 62.6926 80.0637 63.7209 80.0637H82.3255C83.3539 80.0637 84.186 79.2329 84.186 78.2063V55.9175C84.186 54.8908 83.3539 54.0601 82.3255 54.0601Z"
        fill="#637381"
      />
      <path
        opacity="0.24"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6744 57.7749H45.1163C46.1446 57.7749 46.9767 56.9441 46.9767 55.9175C46.9767 54.8908 46.1446 54.0601 45.1163 54.0601H37.6744C36.6461 54.0601 35.814 54.8908 35.814 55.9175C35.814 56.9441 36.6461 57.7749 37.6744 57.7749ZM35.814 63.3471C35.814 62.3204 36.6461 61.4897 37.6744 61.4897H56.279C57.3074 61.4897 58.1395 62.3204 58.1395 63.3471C58.1395 64.3737 57.3074 65.2045 56.279 65.2045H37.6744C36.6461 65.2045 35.814 64.3737 35.814 63.3471ZM37.6744 68.9193C36.6461 68.9193 35.814 69.75 35.814 70.7767C35.814 71.8033 36.6461 72.6341 37.6744 72.6341H56.279C57.3074 72.6341 58.1395 71.8033 58.1395 70.7767C58.1395 69.75 57.3074 68.9193 56.279 68.9193H37.6744ZM35.814 78.2063C35.814 77.1796 36.6461 76.3489 37.6744 76.3489H56.279C57.3074 76.3489 58.1395 77.1796 58.1395 78.2063C58.1395 79.2329 57.3074 80.0637 56.279 80.0637H37.6744C36.6461 80.0637 35.814 79.2329 35.814 78.2063Z"
        fill="#637381"
      />
    </g>
    <defs>
      <filter
        id="filter0_di_2575_2852044"
        x="19.9998"
        y="41.238"
        width="95.9999"
        height="68.5713"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2575_2852044"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2575_2852044"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.48 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2575_2852044"
        />
      </filter>
      <filter
        id="filter1_di_2575_2852044"
        x="19.9998"
        y="26"
        width="95.9999"
        height="47.238"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2575_2852044"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2575_2852044"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.48 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2575_2852044"
        />
      </filter>
    </defs>
  </svg>
);
