// returns the error message to show on the front end on snackbar based on the error code of the request

import { HttpStatusCode, isAxiosError } from 'axios';

const errorMessages: Readonly<Record<number, string>> = {
  [HttpStatusCode.Unauthorized]: 'You do not have the permissions',
  [HttpStatusCode.BadRequest]: 'You sent a bad request',
  [HttpStatusCode.InternalServerError]: 'Oops, something went wrong',
  [HttpStatusCode.NotFound]: 'Request not found',
} as const;

const defaultMessage = 'An error occured';

export default (err: unknown): string => {
  if (!isAxiosError(err)) {
    return defaultMessage;
  }

  const errorStatus = err && err.response && err.response.status;
  if (!errorStatus) {
    return defaultMessage;
  }
  return errorMessages[errorStatus] ?? defaultMessage;
};
