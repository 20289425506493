import { userReducer } from '@autone/ui';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

//@ts-ignore - not yet type
import bestsellersSortReducer from './bestsellersSort';
//@ts-ignore - not yet type
import configSliceReducer from './config';
//@ts-ignore - not yet type
import explorerReducer from './explorer';
import filterReducer from './filters';
//@ts-ignore - not yet type
import priceOptionsReducer from './price-options';
//@ts-ignore - not yet type
import retailCalendarReducer from './retailCalendar';
//@ts-ignore - not yet type
import selectedProductReducer from './selected-product';

const store = configureStore({
  reducer: {
    user: userReducer,
    filters: filterReducer,
    bestsellersSort: bestsellersSortReducer,
    config: configSliceReducer,
    priceOptions: priceOptionsReducer,
    selectedProduct: selectedProductReducer,
    retailCalendar: retailCalendarReducer,
    explorer: explorerReducer,
  },
});

export default store;

type DispatchFunc = () => AppDispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: DispatchFunc = useDispatch;
