import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortBy: 'Sales.totalSalesValue',
  orderBy: 'desc',
};

export const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    SELECT_SORT: (state, action) => ({
      ...state,
      sortBy: action.payload,
    }),
    SELECT_ORDER_BY: (state, action) => ({
      ...state,
      orderBy: action.payload,
    }),
  },
});

export const { SELECT_SORT, SELECT_ORDER_BY } = sortSlice.actions;

export default sortSlice.reducer;
