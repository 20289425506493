import { fetchAuthSession, signOut } from 'aws-amplify/auth';

import { getTokenExpiry, redirectToAuth } from './helpers';

export const authRedirectCheckRtkQuery = async () => {
  const { tokens } = await fetchAuthSession();

  if (!tokens) {
    redirectToAuth();
    return { idToken: undefined, accessToken: undefined };
  }
  const { hasTokenExpired } = getTokenExpiry(tokens);

  if (hasTokenExpired) {
    try {
      const { tokens } = await fetchAuthSession();
      return {
        idToken: tokens?.idToken?.toString(),
        accessToken: tokens?.accessToken?.toString(),
      };
    } catch {
      signOut();
    }
  } else {
    return {
      idToken: tokens?.idToken?.toString(),
      accessToken: tokens?.accessToken?.toString(),
    };
  }
};
