export const mixCurrentKeys = (calcType: string | null) =>
  calcType === 'mix'
    ? [
        {
          key: 'wtd',
        },
        {
          key: 'mtd',
        },
        {
          key: 'ytd',
        },
      ]
    : null;

export const mixCompareKeys = (calcType: string | null) =>
  calcType === 'mix'
    ? [
        {
          key: 'wtd-compare',
          type: 'wtd',
        },
        {
          key: 'mtd-compare',
          type: 'mtd',
        },
        {
          key: 'ytd-compare',
          type: 'ytd',
        },
      ]
    : null;

export const compareKeys = [
  {
    key: 'wtd-compare',
    type: 'wtd',
  },
  {
    key: 'mtd-compare',
    type: 'mtd',
  },
  {
    key: 'ytd-compare',
    type: 'ytd',
  },
];

export const percentageKeys = [
  {
    key: 'wtd-compare-percent',
    type: 'wtd',
  },
  {
    key: 'mtd-compare-percent',
    type: 'mtd',
  },
  {
    key: 'ytd-compare-percent',
    type: 'ytd',
  },
];
