import { CubeQuery } from '../types';
import { CubeFilterOption, FilterOutput } from '../types/filters';

const getFilters = (
  filters: CubeFilterOption[] | undefined | null,
  ignoreFilter: string[] | undefined,
) => {
  // if ignore filter is not specified, return all filters
  if (!ignoreFilter || !filters) {
    return { filters };
  }
  // if no filters are set, return all filters
  if (filters.length === 0) {
    return { filters };
  }

  // remove ignored items from array
  const reducedFilters = filters.flatMap((item) =>
    ignoreFilter.includes(item?.member || '') ? [] : item,
  );

  return { filters: reducedFilters };
};

export const queryBuilder = (
  item: CubeQuery,
  activeFilters: FilterOutput[] | undefined | null,
  type: string | undefined,
  filterQuery = false,
) => {
  // if a filter query, just return the query without transformation
  if (filterQuery) {
    const {
      query: {
        timeDimension,
        dimension,
        measure,
        range,
        granularity,
        order,
        limit,
        offset,
        filters,
      },
    } = item;
    return {
      drillDimension: undefined,
      drilldownHeader: undefined,
      query: {
        ...(dimension && { measures: measure }),
        ...(dimension && { dimensions: dimension }),
        ...(timeDimension && {
          timeDimensions: [
            {
              dimension: timeDimension,
              dateRange: range,
              ...(granularity && { granularity }),
            },
          ],
        }),
        filters,
        ...{ order },
        ...{ limit },
        ...{ offset },
      },
    };
  }

  // get active / applied filters
  const filters =
    activeFilters &&
    (activeFilters
      .map((item) =>
        item?.options?.length > 0
          ? {
              member: item?.dimension,
              operator: item?.operator,
              values: item?.options && item?.options.map((val) => val.id),
            }
          : undefined,
      )
      // removed undefined from array - casting here as TS can't infer the removal of undefineds
      .filter((item: any): item is CubeFilterOption[] => item) as
      | CubeFilterOption[]
      | undefined);

  // IF type is drilldown
  // find active filter and take the highest position
  // set that as the dimension
  if (type === 'drilldown') {
    // get the selected filters to work out where we need to drill
    const selectedFilters = filters && filters?.map((filter) => filter?.member);

    // get the drill dimensions
    // if a drill key exists, use that, otherwise take the dimension
    const drilldowns = item?.drilldownDimensions?.map((dim) =>
      dim.drillKey ? dim.drillKey : dim.dimension,
    );

    const findFilteredDimension = selectedFilters
      ?.map((item) => {
        // if first drilldown entry is array we find the first entry
        // it means the drilldown dimension has to be the first in your provided config
        if (item && drilldowns && Array.isArray(drilldowns[0])) {
          return drilldowns[0]?.indexOf(item);
        }
        return drilldowns?.indexOf(item || '');
      })
      .filter((index): index is number => index !== undefined);

    let maxIndex = 0;
    if (findFilteredDimension && findFilteredDimension.length > 0) {
      maxIndex = Math.max(...findFilteredDimension);
    }

    const drillDimension = drilldowns?.filter((_, index) => {
      // return second dimension if array - workaround
      let i;
      if (
        findFilteredDimension?.length === 0 ||
        drilldowns?.length - 1 === maxIndex
      ) {
        // if nothing is filtered or we filter on the lowest dimension,
        // return that array index
        i = maxIndex;
      } else {
        // otherwise add one for drilldown
        i = maxIndex + 1;
      }
      return index === i;
    });

    // get header
    const drilldownHeader = item?.drilldownDimensions?.find((item) =>
      item?.drillKey
        ? item?.drillKey === drillDimension?.[0]
        : item?.dimension === drillDimension?.[0],
    )?.header;

    // get the drilldown dimensions (in case we used the drill key above )
    const filteredDimension: {
      dimension?: string[] | string;
      drillKey?: string;
    } = item?.drilldownDimensions?.find((item) =>
      item?.drillKey
        ? item?.drillKey === drillDimension?.[0]
        : item?.dimension === drillDimension?.[0],
    ) || { dimension: undefined, drillKey: undefined };

    let finalDrillDimension: string | string[] | undefined;
    if (filteredDimension && 'drillKey' in filteredDimension) {
      finalDrillDimension = filteredDimension?.dimension;
    } else if (
      filteredDimension &&
      filteredDimension.dimension &&
      !Array.isArray(filteredDimension.dimension)
    ) {
      finalDrillDimension = [filteredDimension?.dimension];
    }

    // build query including drilldown
    const {
      query: {
        timeDimension,
        measure,
        range,
        granularity,
        order,
        limit,
        offset,
        ignoreFilter,
      },
    } = item;

    return {
      drillDimension: finalDrillDimension,
      drilldownHeader,
      query: {
        measures: measure,
        // if we are passing more than one dimension, do not take the drill dimension
        // temporary solution - needs rework
        ...(finalDrillDimension && { dimensions: finalDrillDimension }),
        ...(timeDimension && {
          timeDimensions: [
            {
              dimension: timeDimension,
              dateRange: range,
              ...(granularity && { granularity }),
            },
          ],
        }),
        ...getFilters(filters, ignoreFilter),
        ...{ order },
        ...{ limit },
        ...{ offset },
      },
    };
  }

  // build query
  const {
    query: {
      timeDimension,
      dimension,
      measure,
      range,
      granularity,
      order,
      limit,
      offset,
      ignoreFilter,
    },
  } = item;

  return {
    drillDimension: undefined,
    drilldownHeader: undefined,
    query: {
      measures: measure,
      ...(dimension && { dimensions: dimension }),
      ...(timeDimension && {
        timeDimensions: [
          {
            dimension: timeDimension,
            dateRange: range,
            ...(granularity && { granularity }),
          },
        ],
      }),
      ...getFilters(filters, ignoreFilter),
      ...{ order },
      ...{ limit },
      ...{ offset },
    },
  };
};
