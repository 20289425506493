import { isKeyInType } from '@autone/ui';
import { orderBy } from 'lodash';

import { ExplorerPerformanceData, RetailPerformanceData } from '../../types';
import { isExplorerData, isRetailData } from '../../types/guards';
import { countryDict } from '../getCountryDict';

const parseDate = (dateString?: string | null) => {
  // Match the format 'Wk.{week} {month} {year}'
  const regex = /Wk\.\s*(\d+)\s+(\w+)\s+(\d+)/;
  const match = dateString?.match(regex);

  if (!match) {
    return null;
  }

  const [_, week, month, year] = match;

  // Map the month string to a number (January = 0, December = 11)
  const monthIndex = new Date(`${month} 1, ${year}`).getMonth();

  return `${year}-${String(monthIndex).padStart(2, '0')}-${String(
    week,
  ).padStart(2, '0')}`;
};

const convertToNumber = (calc: number) => {
  if (!isFinite(calc)) {
    return false;
  }
  return Number(calc) || false;
};

const comparePercentSort = (
  current?: string | number,
  compare?: string | number,
) => {
  const currentNum = Number(current);
  const compareNum = Number(compare);

  if (isNaN(currentNum) || isNaN(compareNum)) {
    return -Infinity;
  }

  if (currentNum > compareNum) {
    return convertToNumber(Math.abs(1 - currentNum / compareNum));
  }
  return convertToNumber(-1 * Math.abs(1 - currentNum / compareNum));
};

export const tableSorter = (
  data: (RetailPerformanceData | ExplorerPerformanceData)[],
  sortColumn: string,
  sortOrder: 'asc' | 'desc',
) => {
  if (
    sortColumn === 'today' ||
    sortColumn === 'wtd' ||
    sortColumn === 'wtd-avt' ||
    sortColumn === 'wtd-upt' ||
    sortColumn === 'mtd' ||
    sortColumn === 'mtd-avt' ||
    sortColumn === 'mtd-upt' ||
    sortColumn === 'ytd' ||
    sortColumn === 'ytd-avt' ||
    sortColumn === 'ytd-upt' ||
    sortColumn === 'Sales.totalSalesValue' ||
    sortColumn === 'Sales.totalSalesQuantity' ||
    sortColumn === 'Inventory.currentOnHandValue' ||
    sortColumn === 'Inventory.currentOnHandQuantity' ||
    sortColumn === 'primary_sales_val' ||
    sortColumn === 'comparison_sales_val' ||
    sortColumn === 'primary_sales_vol' ||
    sortColumn === 'comparison_sales_vol'
  ) {
    return orderBy(
      data,
      (item) => {
        return isKeyInType(item, sortColumn)
          ? convertToNumber(item?.[sortColumn])
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'difference_sales_vol') {
    return orderBy(
      data,
      (item) => {
        return isExplorerData(item)
          ? convertToNumber(
              Number(item?.primary_sales_vol ?? 0) -
                Number(item?.comparison_sales_vol || 0),
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'today-compare') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? convertToNumber(
              Number(item?.today ?? 0) - Number(item?.['today-compare'] || 0),
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'wtd-compare') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? convertToNumber(
              Number(item?.wtd ?? 0) - Number(item?.['wtd-compare'] || 0),
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'mtd-compare') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? convertToNumber(
              Number(item?.mtd ?? 0) - Number(item?.['mtd-compare'] || 0),
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'ytd-compare') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? convertToNumber(
              Number(item?.ytd ?? 0) - Number(item?.['ytd-compare'] || 0),
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'today-compare-percent') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? comparePercentSort(item?.today, item?.['today-compare'])
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'wtd-compare-percent') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? comparePercentSort(item?.wtd, item?.['wtd-compare'])
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'mtd-compare-percent') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? comparePercentSort(item?.mtd, item?.['mtd-compare'])
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'ytd-compare-percent') {
    return orderBy(
      data,
      (item) => {
        return isRetailData(item)
          ? comparePercentSort(item?.ytd, item?.['ytd-compare'])
          : item;
      },

      [sortOrder],
    );
  }

  if (sortColumn === 'difference_sales_val') {
    return orderBy(
      data,
      (item) => {
        return isExplorerData(item)
          ? convertToNumber(
              Number(item?.primary_sales_val || 0) -
                Number(item?.comparison_sales_val || 0),
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'percentage_difference_sales_val') {
    return orderBy(
      data,
      (item) => {
        return isExplorerData(item)
          ? comparePercentSort(
              item?.primary_sales_val || 0,
              item?.comparison_sales_val || 0,
            )
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'percentage_difference_sales_vol') {
    return orderBy(
      data,
      (item) => {
        return isExplorerData(item)
          ? comparePercentSort(
              item?.primary_sales_vol || 0,
              item?.comparison_sales_vol || 0,
            )
          : item;
      },

      [sortOrder],
    );
  }

  if (sortColumn === 'Ticket.shippingCountry') {
    return orderBy(
      data,
      (item) => {
        const shippingCountry = isKeyInType(item, 'Ticket.shippingCountry')
          ? item?.['Ticket.shippingCountry']
          : undefined;

        return isKeyInType(countryDict, shippingCountry)
          ? countryDict[shippingCountry]
          : item;
      },
      [sortOrder],
    );
  }

  if (sortColumn === 'Sales.retailWeek') {
    return orderBy(
      data,
      (item) => {
        const week = isKeyInType(item, 'Sales.retailWeek')
          ? item['Sales.retailWeek']
          : null;
        return parseDate(week);
      },
      [sortOrder],
    );
  }

  return orderBy(data, [sortColumn], [sortOrder]);
};
