import { alpha, Card, SxProps, Typography, useTheme } from '@mui/material';

import { NoDataSvg } from '../../../svgs/NoDataSvg';

type Props = {
  title?: string;
  subtitle?: string;
  variant?: 'grey' | 'white';
  shadow?: boolean;
  sx?: SxProps;
  svgProps?: {
    height?: number | string;
    width?: number | string;
  };
};

const NoDataMessage = ({
  title,
  subtitle,
  variant = 'white',
  shadow = false,
  sx,
  svgProps = { height: 120, width: 120 },
}: Props) => {
  const { height: svgHeight, width: svgWidth } = svgProps;
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:
          variant === 'grey' ? alpha(theme.palette.grey[100], 0.4) : 'white',
        padding: 5,
        ...(!shadow && {
          boxShadow: 'none',
          border: 'none',
          borderRadius: 0,
        }),
        ...sx,
      }}
    >
      <NoDataSvg height={svgHeight} width={svgWidth} />

      {title && (
        <Typography
          sx={{ pt: theme.spacing(1), color: theme.palette.grey[500] }}
          variant="h3"
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          sx={{
            textAlign: 'center',
            pt: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Card>
  );
};

export default NoDataMessage;
