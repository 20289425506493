import { createSlice } from '@reduxjs/toolkit';

export type CalculationConfig = {
  location: string;
  sales: string;
  product: string;
  department: string;
  region: string;
  country: string;
  brand: string;
  color: string;
  salesAssociate: string;
  stylisticGroup: string;
};

const initialState: { calculationConfig: CalculationConfig } = {
  calculationConfig: {
    location: 'actual',
    sales: 'actual',
    product: 'actual',
    department: 'actual',
    region: 'actual',
    country: 'actual',
    brand: 'actual',
    color: 'actual',
    salesAssociate: 'actual',
    stylisticGroup: 'actual',
  },
};

export const configSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    SET_CONFIG: (state, action) => ({
      ...state,
      calculationConfig: {
        ...state.calculationConfig,
        [action.payload.table]: action.payload.calcType,
      },
    }),
  },
});

export const { SET_CONFIG } = configSlice.actions;

export default configSlice.reducer;
